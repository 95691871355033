import React from "react";

import raceinfo from "../../assets/images/raceinfo/race-info-breadcrumb.png";

export default function DownloadPDFbreadcrumbpages() {
  return (
    <div className="breadcrumbsbanner-wrapper inner-pages">
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        <img
          className="img-fluid"
          src={raceinfo}
          width="100%"
          alt="Clock logo"
        />
        <h1 className="breadcrumb-title" style={{color: '#FFDE96', opacity: '0.5'}}>Download Certificate</h1>
      </div>
    </div>
  );
}
