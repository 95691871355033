import React, { useState, useEffect } from "react";
import "../../assets/styles/pages/downloadcertificate/downloaddoc.scss";
import DownloadPDFbreadcrumbpages from "../raceinfo/downloaddocbreadcrumb";
import KanhaRunService from "../services/kanha.service";
import Loading from "./loading.jsx";
import { Button, Modal } from "react-bootstrap";
import Card from "react-bootstrap/Card";

const PDFDownload = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [recordNotFound, setRecordNotFound] = useState(false);
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [name, setName] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");
  const [focusInput, setFocusInput] = useState(false);
  const kanhaApi = new KanhaRunService();

  const handleDownload = async (participant_id, first_name, last_name) => {
    setLoading(true);
    kanhaApi
      .certificateDownload(participant_id)
      .then((response) => {
        setLoading(false);
        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            first_name = first_name + " " + last_name;
            first_name = first_name.replace(/\s+/g, "");
            setName(first_name);
            setPdfData(response.data.base64Pdf);
          } else {
            // console.log("error");
          }
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  useEffect(() => {
    if (pdfData) {
      const binaryData = atob(pdfData);
      const uint8Array = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download =
        "Granules_GKR_Participant_Certificate_" + name + "_19_Nov_2023.pdf";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [pdfData]);

  const handleSearch = async () => {
    if (!searchValue.trim()) {
      setValidationMessage("Please enter PNR/Unique Id/Email");
      setFocusInput(true);
      return;
    }
    setLoading(true);
    const payLoad = {
      search_value: searchValue,
    };
    kanhaApi
      .getSearchResult(payLoad)
      .then((response) => {
        setLoading(false);
        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            setSearchResults(response.data.data);
            setRecordNotFound(false);
          } else {
            setSearchResults([]);
            setRecordNotFound(true);
          }
        } else {
          setSearchResults([]);
          setRecordNotFound(true);
        }
      })
      .catch(function (error) {
        console.error("Error fetching data:", error);
      });
  };
  const handleClear = () => {
    setSearchValue("");
    setSearchResults([]);
    setRecordNotFound(false);
    setValidationMessage("");
  };
  return (
    <>
      <section className="download-certificate inner-pages">
        <DownloadPDFbreadcrumbpages />
        <div className="container" style={{ marginBottom: "100px" }}>
          <div className="row py-4">
            <div className="col-md-12">
              <h4 className="title-heading">Download Certificate</h4>

              <div className="center-container">
                <div className="search-input-div">
                  <label className="fw-bold">
                    Search by using PNR/Unique Id/Email
                  </label>
                  <br />
                  <input
                    type="text"
                    className="formControl"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="PNR/Unique Id/Email"
                    onFocus={() => setValidationMessage("")}
                    autoFocus={focusInput}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleSearch();
                      setSearchButtonClicked(true);
                    }}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary clear-mbl"
                    onClick={handleClear}
                  >
                    Clear
                  </button>
                </div>

                {validationMessage && (
                  <span className="error-message-span">
                    {validationMessage}
                  </span>
                )}
                {searchButtonClicked && recordNotFound && (
                  <p style={{ color: "red", marginTop: "5px" }}>
                    No records found.
                  </p>
                )}
              </div>

              {searchButtonClicked && (
                <>
                  {!recordNotFound && (
                    <div className="row mt-4">
                      {searchResults.map((result) => (
                        <div
                          key={result.participant_id}
                          className="col-md-4 col-sm-6"
                        >
                          <div className="card cards-list-wrapper hand">
                            <Card>
                              <Card.Body>
                                <ul className="result-display-card">
                                  <li>
                                    <span className="left-label">Name: </span>
                                    <b className="right-value">
                                      {`${result.first_name
                                        .charAt(0)
                                        .toUpperCase()}${result.first_name.slice(
                                        1
                                      )} ${result.last_name
                                        .charAt(0)
                                        .toUpperCase()}${result.last_name.slice(
                                        1
                                      )}`}
                                    </b>
                                  </li>
                                  <li>
                                    <span className="left-label">
                                      Email Id:{" "}
                                    </span>
                                    <b className="right-value">
                                      {result.email_address}
                                    </b>
                                  </li>
                                  <li>
                                    <span className="left-label">
                                      Unique Id:{" "}
                                    </span>
                                    <b className="right-value">
                                      {result.unique_id}
                                    </b>
                                  </li>
                                  <li>
                                    <span className="left-label"> PNR: </span>
                                    <b className="right-value">
                                      {result.pnr_no}
                                    </b>
                                  </li>
                                  <li>
                                    <span className="left-label">
                                      {" "}
                                      Race Name:{" "}
                                    </span>
                                    <b className="right-value">
                                      {result.race_category_name}
                                    </b>
                                  </li>

                                  <li className="mt-3">
                                    {result.race_category_id != 2 &&
                                    result.race_category_id != 3 &&
                                    result.race_category_id != 4 ? (
                                      <Button
                                        onClick={() =>
                                          handleDownload(
                                            result.participant_id,
                                            result.first_name,
                                            result.last_name
                                          )
                                        }
                                        className="btn"
                                        style={{
                                          backgroundColor: "#0CAE10",
                                          color: "#fff",
                                        }}
                                      >
                                        Download Certificate
                                      </Button>
                                    ) : (
                                      <div className="mt-3 mb-3">
                                        <Button
                                        onClick={() => window.open("https://www.timingmiles.com/result-page2.php?eid=66&cid=162", '_blank')}
                                        className="btn"
                                        style={{
                                          backgroundColor: "#0CAE10",
                                          color: "#fff",
                                        }}
                                      > Download Certificate</Button>
                                        {/* <h4 style={{
                                          color: "#fb8c05",
                                        }}>Coming Soon</h4> */}
                                      </div>
                                    )}
                                  </li>
                                </ul>
                              </Card.Body>
                            </Card>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {loading === true && (
          <Modal id="loading" show={loading} style={{ marginTop: "50vh" }}>
            <Loading />
          </Modal>
        )}
      </section>
    </>
  );
};
export default PDFDownload;
