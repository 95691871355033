import React from "react";

import DownloadDoc from "../components/sharedComponents/downloadCertificate";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

// markup
const DownloadCertificate = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - Download Certificate" />
      <Layout isSticky>
        <DownloadDoc />
      </Layout>
    </div>
  );
};

export default DownloadCertificate;
